'use client'

import { useRouter } from 'next/navigation'
import { Button, Checkbox, Input, Link } from '@nextui-org/react'
import { Controller, FieldErrors, useForm } from 'react-hook-form'
import NonSSRWrapper from '@/components/NonSSRWrapper'
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs'
import { Database } from '@/types/supabase'
import React, { useEffect, useState } from 'react'

type FormData = {
    email: string
    password: string
    confirmPassword: string
    otp: boolean
}

export default function Login() {
    const supabase = createClientComponentClient<Database>()
    const router = useRouter()
    const [otpSuccess, setOtpSuccess] = useState<boolean | undefined>()
    const {
        control,
        register,
        unregister,
        watch,
        getValues,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<FormData>({
        defaultValues: { otp: false },
    })

    const otp = watch('otp')

    const handleSignIn = async (formInput: FormData) => {
        if (otp) {
            const { data, error } = await supabase.auth.signInWithOtp({
                email: formInput.email,
                options: {
                    emailRedirectTo: '/auth/confirm',
                    shouldCreateUser: false,
                },
            })

            if (!error) {
                //todo: show success message vs redirect
                return setOtpSuccess(true)
            }
            const [eType, eMessage] = error.toString().split(': ')
            setError('root.serverError', { message: eMessage })
        } else {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: formInput.email,
                password: formInput.password,
            })
            if (error) return setError('root.serverError', { message: error.message })
            router.refresh()
        }
    }

    const handleErrors = (errors: FieldErrors<FormData>) => {
        console.log(errors)
    }

    return (
        <NonSSRWrapper>
            {otpSuccess ? (
                <div className={'success'}>Please check your email for the link to log in!</div>
            ) : (
                <form
                    className={'w-full max-w-lg sm:w-9/12'}
                    onSubmit={handleSubmit(handleSignIn, handleErrors)}
                >
                    <h1>Log in to your account</h1>
                    <div className={'flex w-full flex-col gap-3'}>
                        <div>
                            <Input
                                size="sm"
                                label={'Email'}
                                {...register('email', {
                                    required: 'Email is Required!!!',
                                })}
                                color={'primary'}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name="otp"
                                render={({ field: { onChange, value } }) => (
                                    <Checkbox onValueChange={onChange} isSelected={value}>
                                        Use Email Link instead of password {value}
                                    </Checkbox>
                                )}
                            />
                        </div>
                        {!otp && (
                            <div>
                                <Controller
                                    control={control}
                                    name="password"
                                    rules={{
                                        required: 'Password is Required!!!',
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            size="sm"
                                            {...field}
                                            label={'Password'}
                                            color={'primary'}
                                            type="password"
                                        />
                                    )}
                                />
                            </div>
                        )}
                        {errors.root?.serverError?.message === 'Signups not allowed for otp' ? (
                            <small className="text-danger">
                                Please sign up first: <Link href={'/sign-up'}>Sign Up</Link>
                            </small>
                        ) : (
                            errors.root?.serverError?.message && (
                                <small className="text-danger">
                                    {errors.root.serverError.message}
                                </small>
                            )
                        )}
                        <div className={'flex justify-end'}>
                            <Button type="submit" color={'primary'}>
                                Sign in
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </NonSSRWrapper>
    )
}
